@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Dana;
    src: url(./font/DanaFaNum.woff2);
  }

  body {
    @apply font-Dana;
  }
}
